import { ReactElement } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { StyledSignInSectionContainer } from './sign-in-section.styled-components';
import { SignInButton } from '../sign-in-button/sign-in-button';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ISignUpFormProps {
  onSignInPress: () => void;
}

export const SignInSection = ({ onSignInPress }: ISignUpFormProps): ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { auth0Migration } = useFlags();

  return (
    <StyledSignInSectionContainer>
      <Typography variant='h4' style={{ paddingBottom: theme.spacing(4) }}>
        {t('lander.signInSection.signInLabel')}
      </Typography>
      <Box display='flex' flexDirection='column' gap={6}>
        {auth0Migration && <Typography>{t('lander.signInSection.signInHelperText')}</Typography>}
        <SignInButton onSignInPress={onSignInPress} />
      </Box>
    </StyledSignInSectionContainer>
  );
};
