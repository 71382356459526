import { ReactElement } from 'react';
import { StyledSignInButton } from './sign-in-button.styled-components';
import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ISignInButtonProps {
  onSignInPress: () => void;
}

export const SignInButton = ({ onSignInPress }: ISignInButtonProps): ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { auth0Migration } = useFlags();

  const rebrandContent = (
    <Typography style={{ marginLeft: theme.spacing(1) }}>{t('lander.prescryptivePro')}</Typography>
  );

  return (
    <Box style={{ paddingLeft: theme.spacing(15), paddingRight: theme.spacing(15) }}>
      {auth0Migration && (
        <StyledSignInButton
          onClick={onSignInPress}
          size='large'
          variant='contained'
          style={{
            display: 'flex',
            alignItems: 'center',
            background: theme.palette.purpleScale['300'],
          }}
        >
          <Typography>{t('lander.signInButtonAuth0')}</Typography>
          {rebrandContent}
        </StyledSignInButton>
      )}
    </Box>
  );
};
